import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { countryList } from "../../Cuntries";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constants/url";

const OrderDetails = () => {
  window.scrollTo(0, 0);
  const [useDifferentBilling, setUseDifferentBilling] = useState(false);
  const navigate = useNavigate();

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isCodChecked, setIsCodChecked] = useState(false);
  const [isPayNowChecked, setIsPayNowChecked] = useState(false);

  const handleToggleCod = () => {
    setIsCodChecked(!isCodChecked);
    setIsPayNowChecked(false); // Deselect Pay Now if COD is selected
  };

  const handleTogglePayNow = () => {
    setIsPayNowChecked(!isPayNowChecked);
    setIsCodChecked(false); // Deselect COD if Pay Now is selected
  };

  const orderDetails = useSelector((state) => state.order.orderDetails);

  const totalPrice = orderDetails.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.price;
  }, 0);

  const user = JSON.parse(localStorage.getItem("userInformation"));

  useEffect(() => {
    const userInfo = localStorage.getItem("userInformation");
    if (!userInfo) {
      toast.warning("Please log in or sign up to buy products");

      // Delay navigation to ensure the toast is visible
      setTimeout(() => {
        navigate("/signup");
      }, 1500); // 1.5 seconds delay
    }
  }, [navigate]);

  const calculateSubtotal = () => {
    return orderDetails.reduce(
      (acc, item) => acc + item.discountPrice * item.quantity,
      0
    );
  };

  const calculateTax = (totalPrice) => {
    return (totalPrice * 0) / 100;
  };

  const shipping = 0;

  const calculateTotal = (subtotal, tax) => {
    return subtotal + shipping + tax;
  };

  const subtotal = calculateSubtotal();
  const tax = calculateTax(totalPrice);
  const total = calculateTotal(subtotal, tax);

  const handlePhonePePayment = async (e) => {
    try {
      // Step 1: Initiate the payment

      const orderData = await createOrder(e);

      const response = await axios.post(BASE_URL + "/pay", {
        amount: total, // Assuming total is the payment amount
        orderId: orderData.data._id,
        userId: orderData.data.userId,
      });

      if (true) {
        try {
          // Step 1: Initiate the payment

          toast.success("Payment initiated successfully!");
          console.log("Payment initiated:", response.data);

          const redirectUrl =
            response.data.data.instrumentResponse.redirectInfo.url;

          window.location.href = redirectUrl;
        } catch (error) {
          toast.error("Error initiating payment. Please try again.");
          console.error("Error initiating payment:", error.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // State for form fields
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    country: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
    billingFirstName: "",
    billingLastName: "",
    billingCountry: "",
    billingPhone: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingPinCode: "",
  });

  // State for validation errors
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    country: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
    billingFirstName: "",
    billingLastName: "",
    billingCountry: "",
    billingPhone: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingPinCode: "",
  });

  // Phone number validation function
  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };
  const validatePincode = (number) => {
    const pincodRegex = /^\d{6}$/;
    return pincodRegex.test(number);
  };

  // Handle validation on input change
  const handleInputChange = (e, field, isBilling = false) => {
    const { value } = e.target;
    let error = "";

    if (field === "phone" || field === "billingPhone") {
      if (!validatePhoneNumber(value)) {
        error = "Phone number must be 10 digits";
      }
    } else if (!value) {
      error = "This field is required";
    }
    if (field === "pinCode" || field === "billingPinCode") {
      if (!validatePincode(value)) {
        error = "Pin code must be 6 digits";
      }
    } else if (!value) {
      error = "This field is required";
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isCodChecked) {
      try {
        const response = await createOrder();
        console.log(response.data, "195");
        if (response.status === true) {
          // toast.success("Order created successfully!");
          navigate(`/order-success/${response.data.order._id}`);
        }
      } catch (error) {
        console.error("Error creating order:", error);
      }
    } else {
      handlePhonePePayment();
    }
  };

  const createOrder = async (e) => {
    const requiredFields = [
      "firstName",
      "lastName",
      "country",
      "phone",
      "address",
      "city",
      "state",
      "pinCode",
    ];

    if (useDifferentBilling) {
      requiredFields.push(
        "billingFirstName",
        "billingLastName",
        "billingCountry",
        "billingPhone",
        "billingAddress",
        "billingCity",
        "billingState",
        "billingPinCode"
      );
    }

    let hasErrors = false;
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
        hasErrors = true;
      }
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...newErrors,
    }));

    if (!hasErrors) {
      const user = JSON.parse(localStorage.getItem("userInformation"));
      if (user) {
        try {
          const orderData = {
            userId: user._id,
            name: `${formData.firstName} ${formData.lastName}`,
            email: user.email,
            city: formData.city,
            address: formData.address,
            pincode: formData.pinCode,
            state: formData.state,
            country: formData.country,
            phone: formData.phone,
            products: orderDetails.map((product) => ({
              productId: product._id,
              productName: product.name,
              productImage: product.image,
              price: product.discountPrice,
              quantity: product.quantity,
              subtotal: product.discountPrice * product.quantity, // Assuming price * quantity gives subtotal
            })),
            shipping,
            total,
          };

          const response = await axios.post(
            BASE_URL + "/orders/create-order",
            orderData,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
            }
          );

          if (response.status === 200) {
            toast.success("Order created successfully!");
            return { status: true, data: response.data };
          } else {
            console.error(response.data.message);
            return { status: false, message: response.data.message };
          }
        } catch (error) {
          console.error("Error creating order:", error);
        }
      } else {
        return { status: false };
      }
      return { status: false };
    }
  };

  return (
    <div className="container max-w-screen-xl mx-auto p-6">
      <div className="flex flex-col md:flex-row gap-8">
        {/* Column 1: Billing Details and Payment Options */}
        <div className="w-full md:w-2/3 order-2 lg:order-1 md:order-1">
          <form onSubmit={handleSubmit}>
            {/* Billing Details */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-4">Billing Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block mb-2">
                    First Name
                    <span className="text-[red]" style={{ color: "red" }}>
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                      errors.firstName ? "border border-red-600" : ""
                    }`}
                    placeholder="Enter your first name"
                    required
                    onChange={(e) => handleInputChange(e, "firstName")}
                  />
                  {errors.firstName && (
                    <p className="text-[red] text-sm mt-1">
                      {errors.firstName}
                    </p>
                  )}
                </div>
                <div>
                  <label className="block mb-2">
                    Last Name
                    <span className="text-[red]" style={{ color: "red" }}>
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                      errors.lastName ? "border border-red-600" : ""
                    }`}
                    placeholder="Enter your last name"
                    required
                    onChange={(e) => handleInputChange(e, "lastName")}
                  />
                  {errors.lastName && (
                    <p className="text-[red] text-sm mt-1">{errors.lastName}</p>
                  )}
                </div>
              </div>
              <div>
                {/* Other components and JSX */}

                <div className="mb-4">
                  <label className="block mb-2">
                    Country
                    <span className="text-[red]" style={{ color: "red" }}>
                      *
                    </span>
                  </label>
                  <select
                    className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                      errors.country ? "border border-red-600" : ""
                    }`}
                    required
                    onChange={(e) => handleInputChange(e, "country")}
                  >
                    <option value="">Select your country</option>
                    {countryList.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <p className="text-[red] text-sm mt-1">{errors.country}</p>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <label className="block mb-2">
                  Phone
                  <span className="text-[red]" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                    errors.phone ? "border border-red-600" : ""
                  }`}
                  placeholder="Enter 10-digit phone number"
                  maxLength={10}
                  required
                  onChange={(e) => handleInputChange(e, "phone")}
                />
                {errors.phone && (
                  <p className="text-[red] text-sm mt-1">{errors.phone}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="block mb-2">
                  Address
                  <span className="text-[red]" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                    errors.address ? "border border-red-600" : ""
                  }`}
                  placeholder="Enter your address"
                  required
                  onChange={(e) => handleInputChange(e, "address")}
                />
                {errors.address && (
                  <p className="text-[red] text-sm mt-1">{errors.address}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="block mb-2">Apartment, suite, etc.</label>
                <input
                  type="text"
                  className="w-full p-2 bg-[#F5F5F5] rounded-lg"
                  placeholder="Enter additional address information"
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                <div>
                  <label className="block mb-2">
                    City
                    <span className="text-[red]" style={{ color: "red" }}>
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                      errors.city ? "border border-red-600" : ""
                    }`}
                    placeholder="Enter your city"
                    required
                    onChange={(e) => handleInputChange(e, "city")}
                  />
                  {errors.city && (
                    <p className="text-[red] text-sm mt-1">{errors.city}</p>
                  )}
                </div>
                <div>
                  <label className="block mb-2">
                    State
                    <span className="text-[red]" style={{ color: "red" }}>
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                      errors.state ? "border border-red-600" : ""
                    }`}
                    placeholder="Enter your state"
                    required
                    onChange={(e) => handleInputChange(e, "state")}
                  />
                  {errors.state && (
                    <p className="text-[red] text-sm mt-1">{errors.state}</p>
                  )}
                </div>
                <div>
                  <label className="block mb-2">
                    Pin Code
                    <span className="text-[red]" style={{ color: "red" }}>
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                      errors.pinCode ? "border border-red-600" : ""
                    }`}
                    placeholder="Enter your pin code"
                    required
                    onChange={(e) => handleInputChange(e, "pinCode")}
                  />
                  {errors.pinCode && (
                    <p className="text-[red] text-sm mt-1">{errors.pinCode}</p>
                  )}
                </div>
              </div>
              {/* diffrent billing address */}
              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  id="differentBilling"
                  className="mr-2"
                  checked={useDifferentBilling}
                  onChange={(e) => setUseDifferentBilling(e.target.checked)}
                />
                <label htmlFor="differentBilling">
                  Use different billing address
                </label>
              </div>

              {useDifferentBilling && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <label className="block mb-2">
                      First Name
                      <span className="text-[red]" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                        errors.billingFirstName ? "border border-red-600" : ""
                      }`}
                      placeholder="Enter  first name"
                      required
                      onChange={(e) =>
                        handleInputChange(e, "billingFirstName", true)
                      }
                    />
                    {errors.billingFirstName && (
                      <p className="text-[red] text-sm mt-1">
                        {errors.billingFirstName}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block mb-2">
                      Last Name
                      <span className="text-[red]" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                        errors.billingLastName ? "border border-red-600" : ""
                      }`}
                      placeholder="Enter last name"
                      required
                      onChange={(e) =>
                        handleInputChange(e, "billingLastName", true)
                      }
                    />
                    {errors.billingLastName && (
                      <p className="text-[red] text-sm mt-1">
                        {errors.billingLastName}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block mb-2">
                      Country
                      <span className="text-[red]" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <select
                      className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                        errors.billingCountry ? "border border-red-600" : ""
                      }`}
                      required
                      onChange={(e) =>
                        handleInputChange(e, "billingCountry", true)
                      }
                    >
                      <option value="">Select your country</option>
                      {countryList.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                    {errors.billingCountry && (
                      <p className="text-[red] text-sm mt-1">
                        {errors.billingCountry}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block mb-2">
                      Phone
                      <span className="text-[red]" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                        errors.billingPhone ? "border border-red-600" : ""
                      }`}
                      placeholder="Enter phone"
                      required
                      onChange={(e) =>
                        handleInputChange(e, "billingPhone", true)
                      }
                    />
                    {errors.billingPhone && (
                      <p className="text-[red] text-sm mt-1">
                        {errors.billingPhone}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block mb-2">
                      Address
                      <span className="text-[red]" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                        errors.billingAddress ? "border border-red-600" : ""
                      }`}
                      placeholder="Enter address"
                      required
                      onChange={(e) =>
                        handleInputChange(e, "billingAddress", true)
                      }
                    />
                    {errors.billingAddress && (
                      <p className="text-[red] text-sm mt-1">
                        {errors.billingAddress}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block mb-2">Apartment, suite, etc.</label>
                    <input
                      type="text"
                      className="w-full p-2 bg-[#F5F5F5] rounded-lg"
                      placeholder="Enter additional address information"
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4 w-full">
                    <div className="w-full">
                      <label className="block mb-2">
                        City
                        <span className="text-[red]">*</span>
                      </label>
                      <input
                        type="text"
                        className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                          errors.billingCity ? "border border-red-600" : ""
                        }`}
                        placeholder="Enter city"
                        required
                        onChange={(e) =>
                          handleInputChange(e, "billingCity", true)
                        }
                      />
                      {errors.billingCity && (
                        <p className="text-[red] text-sm mt-1">
                          {errors.billingCity}
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <label className="block mb-2">
                        State
                        <span className="text-[red]">*</span>
                      </label>
                      <input
                        type="text"
                        className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                          errors.billingState ? "border border-red-600" : ""
                        }`}
                        placeholder="Enter state"
                        required
                        onChange={(e) =>
                          handleInputChange(e, "billingState", true)
                        }
                      />
                      {errors.billingState && (
                        <p className="text-[red] text-sm mt-1">
                          {errors.billingState}
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <label className="block mb-2">
                        Pin Code
                        <span className="text-[red]">*</span>
                      </label>
                      <input
                        type="text"
                        className={`w-full p-2 bg-[#F5F5F5] rounded-lg ${
                          errors.billingPinCode ? "border border-red-600" : ""
                        }`}
                        placeholder="Enter pin code"
                        required
                        onChange={(e) =>
                          handleInputChange(e, "billingPinCode", true)
                        }
                      />
                      {errors.billingPinCode && (
                        <p className="text-[red] text-sm mt-1">
                          {errors.billingPinCode}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* Payment Options */}
            <div className="bg-white p-6 rounded-lg shadow-md mt-6">
              <h2 className="text-2xl font-semibold mb-4">Payment Options</h2>

              {/* Cash on Delivery Option as a Button with Checkbox */}
              <div
                className={`flex items-center justify-start p-2 mb-4 rounded-md cursor-pointer w-full ${
                  isCodChecked
                    ? "bg-[green] text-[white]"
                    : "bg-[#F5F5F5] text-gray-700"
                }`}
                // onClick={handleToggleCod}
              >
                <input
                  type="checkbox"
                  id="cod"
                  name="paymentMethod"
                  className="mr-2 cursor-pointer"
                  checked={isCodChecked}
                  onChange={handleToggleCod}
                />
                <label
                  htmlFor="cod"
                  className="cursor-pointer w-full text-left"
                >
                  Cash on Delivery (COD)
                </label>
              </div>

              {/* Separator Line */}
              <div className="border-t border-gray-300 mb-4"></div>

              {/* Pay Now Option as a Button with Checkbox */}
              <div
                className={`flex items-center justify-start p-2 mb-4 rounded-md cursor-pointer w-full ${
                  isPayNowChecked
                    ? "bg-[green] text-[white]"
                    : "bg-[#F5F5F5] text-gray-700"
                }`}
              >
                <input
                  type="checkbox"
                  id="payNow"
                  name="paymentMethod"
                  className="mr-2 cursor-pointer"
                  checked={isPayNowChecked}
                  onChange={handleTogglePayNow}
                />
                <label
                  htmlFor="payNow"
                  className="cursor-pointer w-full text-left"
                >
                  Pay Now
                </label>
              </div>
            </div>

            {/* Place Order Button */}

            <div className="mt-6">
              <button
                type="submit"
                disabled={!isCodChecked && !isPayNowChecked}
                className="w-full bg-[#e3c66c] text-white py-3 rounded-lg font-semibold hover:bg-[#f4e5c8]"
              >
                Place Order
              </button>
            </div>
          </form>
        </div>
        {/* Column 2: Order Summary */}
        <div className="w-full md:w-1/3 order-1 lg:order-2 md:order-2">
          <div className="bg-[#F5F5F5] p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Your Order</h2>

            {orderDetails.length &&
              orderDetails.map((item, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center mb-4"
                >
                  <img
                    src={item.image}
                    alt="Product"
                    className="w-12 h-12 rounded"
                  />
                  <div className="flex-1 px-4">
                    <p className="font-medium">
                      {item.quantity} X {item.name}
                    </p>
                  </div>
                  <div>
                    <p>₹{item.discountPrice * item.quantity}</p>
                  </div>
                </div>
              ))}

            <div className="border-t border-gray-300 my-4"></div>
            <div className="flex justify-between mb-4">
              <p>Subtotal</p>
              <p>₹{subtotal}</p>
            </div>
            <div className="flex justify-between mb-4">
              <p>Shipping</p>
              <p>{shipping === 0 ? "Free Shipping" : shipping}</p>
            </div>
            <div className="flex justify-between mb-4">
              <p>Tax</p>
              <p>₹{tax}</p>
            </div>
            <div className="border-t border-gray-300 my-4"></div>
            <div className="flex justify-between font-semibold text-lg">
              <p>Total</p>
              <p>₹{total}</p>
            </div>
            <p className="text-right text-sm text-gray-500 mt-2">
              Including all taxes
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
