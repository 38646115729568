import React from "react";
import { useTable, usePagination } from "react-table";

const TableComponent = ({ columns = [], data = [] }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of using rows, we'll use page
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    pageCount, // Add pageCount from useTable
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // Pass initial settings for pagination
    },
    usePagination
  );

  return (
    <>
      <div style={{ overflowX: "auto", width: "100%" }}>
        <table
          {...getTableProps()}
          style={{
            minWidth: "100%",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead style={{ backgroundColor: "#f7fafc" }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f7fafc",
                      padding: "8px 16px",
                      fontWeight: "bold",
                      textAlign: "left",
                      borderBottom: "1px solid #e2e8f0",
                      whiteSpace: "nowrap", // Ensures headers stay in one line
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            style={{ backgroundColor: "#ffffff", borderCollapse: "collapse" }}
          >
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "8px 16px",
                        whiteSpace: "nowrap", // Ensures cells stay in one line
                        textAlign: "left",
                        borderBottom: "1px solid #e2e8f0",
                        position:
                          cell.column.id === "actions" ? "sticky" : "initial",
                        right: cell.column.id === "actions" ? 0 : "initial",
                        backgroundColor: "#ffffff",
                        zIndex: cell.column.id === "actions" ? 1 : "initial",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default TableComponent;
