import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants/url";

const ModalComponent = ({
  isOpen,
  onRequestClose,
  product,
  onProductUpdated,
}) => {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    image: "",
    category: "",
    description: "",
    price: "",
    discountPrice: "",
    discountPercentage: "",
    variants: [],
    size: "",
    countInStock: "",
    productCode: "",
  });

  useEffect(() => {
    if (product) {
      setFormData({
        id: product._id || "",
        name: product.name || "",
        image: product.image || "",
        category: product.category || "",
        description: product.description || "",
        price: product.price || "",
        discountPrice: product.discountPrice || "",
        discountPercentage: product.discountPercentage || "",
        variants: product.variants || [],
        size: product.size || "",
        countInStock: product.countInStock || "",
        productCode: product.productCode || "",
      });
    }
  }, [product]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      "name",
      "image",
      "category",
      "description",
      "price",
      "discountPrice",
      "discountPercentage",
      "countInStock",
      "productCode",
    ];
    const emptyFields = requiredFields.filter(
      (field) => !formData[field] || formData[field].length === 0
    );

    if (emptyFields.length > 0) {
      toast.error("Please fill all required fields");
      return;
    }

    const convertToJsDelivrCDN = (url) => {
      if (url.includes("github.com")) {
        return url
          .replace("github.com", "cdn.jsdelivr.net/gh")
          .replace("/blob/", "/");
      }
      return url;
    };

    // Usage Example
    let imageUrl = convertToJsDelivrCDN(formData.image);
    console.log(imageUrl, "89");

    try {
      const token = localStorage.getItem("authToken");

      if (!token) {
        toast.error("You need to be logged in.");
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      if (formData.id) {
        // Update product
        await axios.put(`${BASE_URL}/products/${formData.id}`, formData, {
          headers,
        });
        toast.success("Product updated successfully");
      } else {
        // Add new product
        await axios.post(`${BASE_URL}/products`, formData, { headers });
        toast.success("Product added successfully");
      }

      onRequestClose();
      onProductUpdated();
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response?.data?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="relative max-w-lg mx-auto my-10 p-6 bg-[white] border border-gray-300 rounded shadow-lg"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-50"
    >
      <button
        onClick={onRequestClose}
        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="max-h-[70vh] overflow-y-auto p-4">
        <h2 className="text-xl mb-4">
          {formData.id ? "Edit Product" : "Add Product"}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <label className="block mb-2">
              Product Name <span className="text-[red]">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">
              Image URL <span className="text-[red]">*</span>
            </label>
            <input
              type="text"
              name="image"
              value={formData.image}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">
              Category <span className="text-[red]">*</span>
            </label>
            <select
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            >
              <option value="" disabled>
                Select a category
              </option>
              <option value="Anklets">Anklets</option>
              <option value="Bracelets">Bracelets</option>
              <option value="Rings">Rings</option>
              <option value="Earrings">Earrings</option>
              <option value="Chains">Chains</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block mb-2">
              Description <span className="text-[red]">*</span>
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">
              Price <span className="text-[red]">*</span>
            </label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">
              Discount Price <span className="text-[red]">*</span>
            </label>
            <input
              type="number"
              name="discountPrice"
              value={formData.discountPrice}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">
              Discount Percentage <span className="text-[red]">*</span>
            </label>
            <input
              type="number"
              name="discountPercentage"
              value={formData.discountPercentage}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Variants (Comma Separated)</label>
            <input
              type="text"
              name="variants"
              value={formData.variants.join(", ")}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  variants: e.target.value.split(","),
                }))
              }
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Size</label>
            <input
              type="text"
              name="size"
              value={formData.size}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">
              Count In Stock <span className="text-[red]">*</span>
            </label>
            <input
              type="number"
              name="countInStock"
              value={formData.countInStock}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">
              Product Code <span className="text-[red]">*</span>
            </label>
            <input
              type="text"
              name="productCode"
              value={formData.productCode}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="A-1"
              required
            />
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="bg-[green] text-[white] px-4 py-2 rounded"
              onClick={handleSubmit}
            >
              {formData.id ? "Update" : "Add"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ModalComponent;
