import React, { useState, useEffect } from "react";
import {
  FaRegHeart,
  FaChevronDown,
  FaChevronUp,
  FaHeart,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner";
import axios from "axios";
import {
  addToCart,
  fetchProductsByCategory,
} from "../../../store/slices/productSlice";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../store/slices/wishlistSlice";
import { BASE_URL } from "../../../constants/url";

const Anklets = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [banner, setBanner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  // Safely access Redux state with iOS compatibility
  const { categoryProducts, productLoading } = useSelector(
    (state) => state.products
  );

  const wishlist = useSelector((state) => {
    try {
      return state.wishlist.wishlist || [];
    } catch (error) {
      // Handle Safari private mode restrictions
      return [];
    }
  });

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchBanner = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/category/anklets`, {
          signal: controller.signal,
        });

        if (isMounted) {
          const banners = response.data.data.banners;
          setBanner(banners.find((b) => b.category === "Anklets") || null);
        }
      } catch (error) {
        if (isMounted && !axios.isCancel(error)) {
          setError(error.message);
          setLoading(false);
        }
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchBanner();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    dispatch(fetchProductsByCategory("Anklets"));
  }, [dispatch]);

  useEffect(() => {
    // Handle potential iOS Redux hydration delay
    const products = categoryProducts?.["Anklets"] || [];
    setSortedProducts([...products]);
  }, [categoryProducts]);

  const handleAddToCart = (id) => {
    dispatch(addToCart(id));
  };

  const handleWishlistToggle = (product) => {
    const isInWishlist = wishlist.some((item) => item._id === product._id);
    if (isInWishlist) {
      dispatch(removeFromWishlist(product._id));
    } else {
      dispatch(addToWishlist(product));
    }
  };

  const handleSort = (order) => {
    const sorted = [...sortedProducts].sort((a, b) => {
      // Add safety checks for iOS Number handling
      const priceA = a.discountPrice || 0;
      const priceB = b.discountPrice || 0;

      if (order === "lowToHigh") return priceA - priceB;
      if (order === "highToLow") return priceB - priceA;
      return 0;
    });
    setSortedProducts(sorted);
    setIsDropdownOpen(false);
  };

  return (
    <div className="font-poppins">
      <div className="relative w-full h-auto overflow-hidden">
        {loading ? (
          <Spinner />
        ) : banner ? (
          <img
            src={banner.imageLink}
            className="w-full h-32 md:h-64 sm:h-64 lg:h-auto object-cover"
            alt="Category Banner"
            width={1200}
            height={400}
            loading="eager"
            decoding="sync"
          />
        ) : error ? (
          <p className="text-red-500 text-center">{error}</p>
        ) : null}
      </div>

      <div className="w-full bg-[#E9C876] py-6 sm:py-8">
        <div className="container mx-auto text-center px-4">
          <nav aria-label="breadcrumb">
            <ol className="inline-flex items-center space-x-2">
              <li>
                <Link
                  to="/"
                  className="text-gray-700 hover:text-gray-900 text-sm sm:text-base"
                >
                  Home
                </Link>
              </li>
              <span className="text-gray-700"> &gt; </span>
              <li>
                <Link
                  to="/"
                  className="text-gray-700 hover:text-gray-900 text-sm sm:text-base"
                >
                  Shop
                </Link>
              </li>
              <li>
                <span className="text-gray-700"> &gt; </span>
              </li>
              <li className="text-[#5C2020] font-bold text-sm sm:text-base">
                Anklets
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container mx-auto p-4 sm:p-6 lg:p-8">
        <div className="flex flex-col sm:flex-row justify-between items-left border-b-2 border-black pb-4 mb-6">
          <div className="relative inline-block text-left mb-4 sm:mb-0">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="inline-flex justify-center items-center text-gray-700 text-sm sm:text-base"
              aria-label="Sort products"
            >
              Sort by
              {isDropdownOpen ? (
                <FaChevronUp className="ml-2" />
              ) : (
                <FaChevronDown className="ml-2" />
              )}
            </button>
            {isDropdownOpen && (
              <div className="absolute mt-2 w-44 bg-[white] border border-gray-200 rounded shadow-lg z-10">
                <ul className="py-1 text-gray-700 text-sm">
                  <li>
                    <button
                      onClick={() => handleSort("lowToHigh")}
                      className="block px-4 py-2 text-left w-full hover:bg-gray-100"
                    >
                      Low to High
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleSort("highToLow")}
                      className="block px-4 py-2 text-left w-full hover:bg-gray-100"
                    >
                      High to Low
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        <div
          className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4"
          style={{
            minHeight: 0,
            minWidth: 0,
            transform: "translateZ(0)", // iOS hardware acceleration
          }}
        >
          {productLoading ? (
            <div className="flex items-center justify-center col-span-full">
              <Spinner />
            </div>
          ) : sortedProducts.length === 0 ? (
            <div className="col-span-full text-center py-8">
              No products found
            </div>
          ) : (
            sortedProducts.map((product) => (
              <div
                key={product._id}
                className="p-2 sm:p-4"
                style={{ contentVisibility: "auto" }} // Safari perf boost
              >
                <div className="bg-white rounded-lg overflow-hidden relative group">
                  <div className="w-full h-[180px] lg:h-[600px] flex items-center justify-center bg-gray-100 relative">
                    <Link
                      to={`/product/${product._id}`}
                      className="w-full h-full"
                    >
                      <img
                        src={product.image}
                        alt={product.name || "Jewelry Item"}
                        className="w-full h-full rounded-lg object-cover"
                        loading="lazy"
                        decoding="async"
                        width={300}
                        height={300}
                        style={{
                          transform: "translateZ(0)",
                          backfaceVisibility: "hidden",
                        }}
                      />
                    </Link>
                    <button
                      onClick={() => handleWishlistToggle(product)}
                      className="absolute top-4 right-4 text-2xl hover:text-red-600 transition-colors"
                      aria-label="Wishlist"
                    >
                      {wishlist.some((item) => item._id === product._id) ? (
                        <FaHeart color="red" />
                      ) : (
                        <FaRegHeart color="black" />
                      )}
                    </button>
                  </div>

                  <div className="p-4 text-left">
                    <h3
                      className="text-sm sm:text-lg lg:text-xl font-semibold mb-1"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "25ch",
                      }}
                    >
                      {product.name}
                    </h3>
                    <div className="flex flex-col sm:flex-row items-start sm:items-center space-x-0 sm:space-x-2 mb-4">
                      <div className="flex items-center space-x-2">
                        <span className="text-lg sm:text-xl lg:text-2xl font-bold text-red-600">
                          ₹{product.discountPrice ?? "N/A"}
                        </span>
                        {product.price && (
                          <span className="text-sm sm:text-base lg:text-xl line-through text-[#969595]">
                            ₹{product.price}
                          </span>
                        )}
                      </div>
                      {product.discountPercentage && (
                        <span className="text-sm sm:text-base lg:text-lg text-[#5C2028] font-medium mt-2 sm:mt-0 ml-2">
                          ({product.discountPercentage}% OFF)
                        </span>
                      )}
                    </div>
                    <button
                      className="w-full py-2 bg-[#E9C876] text-white font-bold rounded-lg hover:bg-[#d1a963] transition duration-200 text-[14px] lg:text-[16px]"
                      onClick={() => handleAddToCart(product._id)}
                      aria-label="Add to Cart"
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Anklets;
